// "C:\Users\Andreas\Nextcloud\faledia.all.project\Lernbausteine\SWV\1 Hintergrund\3 Darstellungswechsel\Gruppen_Aktivität 1_10er-System\Vorlage_Lernbaustein_Gruppen_Darstellungswechsel_10erSystem_200917.pptx"
function createObject() {
  let example = {
    id: "SDWH2_5er",
    showGroupNames: false,
    checkAfterHowManyItems: 4,
    checkAfterExactItemCount: true,
    workOrder: `
        Sie sehen hier 16 Zahldarstellungen zu insgesamt vier verschiedenen Zahlen im 5er-System. Jede Zahl wird viermal repräsentiert. Ziehen Sie jeweils vier Zahldarstellungen in eine Gruppe, die Ihrer Meinung nach dieselbe Zahl repräsentieren. Bitte beachten Sie: Alle Zahlwerte sind – sofern nicht anderweitig gekennzeichnet – im 10er-System angegeben!
            `,
    possibleCategories: [
      {
        id: 1,
        name: "Darstellungen der Zahl (302)<sub>5</sub>",
      },
      {
        id: 2,
        name: "Darstellungen der Zahl (320)<sub>5</sub>",
      },
      {
        id: 3,
        name: "Darstellungen der Zahl (322)<sub>5</sub>",
      },
      {
        id: 4,
        name: "Darstellungen der Zahl (1233)<sub>5</sub>",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/swve/SDWH2_5er/320_3.jpg"),
        category: 2,
        flex: 2,
      },
      {
        id: 2,
        image: require("@/assets/swve/SDWH2_5er/322_1.jpg"),
        category: 3,
        flex: 2,
      },
      {
        id: 3,
        image: require("@/assets/swve/SDWH2_5er/302_3.jpg"),
        category: 1,
        flex: 2,
      },
      {
        id: 4,
        image: require("@/assets/swve/SDWH2_5er/1233_2.jpg"),
        category: 4,
        flex: 2,
      },
      {
        id: 5,
        image: require("@/assets/swve/SDWH2_5er/1233_4.jpg"),
        category: 4,
        flex: 2,
      },
      {
        id: 6,
        image: require("@/assets/swve/SDWH2_5er/322_2.jpg"),
        category: 3,
        flex: 2,
      },
      {
        id: 7,
        image: require("@/assets/swve/SDWH2_5er/320_1.jpg"),
        category: 2,
        flex: 2,
      },
      {
        id: 8,
        image: require("@/assets/swve/SDWH2_5er/1233_3.jpg"),
        category: 4,
        flex: 2,
      },
      {
        id: 9,
        image: require("@/assets/swve/SDWH2_5er/302_1.jpg"),
        category: 1,
        flex: 2,
      },
      {
        id: 10,
        image: require("@/assets/swve/SDWH2_5er/322_3.jpg"),
        category: 3,
        flex: 2,
      },
      {
        id: 11,
        image: require("@/assets/swve/SDWH2_5er/320_2.jpg"),
        category: 2,
        flex: 2,
      },
      {
        id: 12,
        image: require("@/assets/swve/SDWH2_5er/1233_1.jpg"),
        category: 4,
        flex: 2,
      },
      {
        id: 13,
        image: require("@/assets/swve/SDWH2_5er/302_2.jpg"),
        category: 1,
        flex: 2,
      },
      {
        id: 14,
        image: require("@/assets/swve/SDWH2_5er/320_4.jpg"),
        category: 2,
        flex: 2,
      },
      {
        id: 15,
        image: require("@/assets/swve/SDWH2_5er/322_4.jpg"),
        category: 3,
        flex: 2,
      },
      {
        id: 16,
        image: require("@/assets/swve/SDWH2_5er/302_4.jpg"),
        category: 1,
        flex: 2,
      },
    ],
  };

  const var3richtig = `Drei der Darstellungen zeigen die selbe Zahl, eine zeigt jedoch eine andere Zahl. Die nicht passende Zahl besitzt `;

  const var302falsch = `keine Fünfer.`;

  const var320falsch = `keine Einer.`;

  const var322falsch = `sowohl Einer als auch Fünfer, aber keine Hundertfünfundzwanziger.`;

  const var1233falsch = `einen Hundertfünfundzwanziger.`;

  const varachtung = ` Achtung: Evt. müssen gleiche Elemente in einer der Darstellungen gebündelt werden, wenn dort 5 oder mehr vorhanden sind.
                        Welche Darstellung passt nicht? Versuchen Sie es erneut.`;

  const var2x2richtig = `Die ausgewählten Darstellungen zeigen zwei verschiedene Zahlen. Jeweils zwei der Darstellungen zeigen die dieselbe Zahl. Eine der beiden Zahlen besitzt `;

  const varandereZahl = ` Die andere Zahl besitzt `;

  const var2richtig = `Zwei der Darstellungen zeigen dieselbe Zahl, zwei weitere Darstellungen passen nicht zu dieser Zahl. Die eine nicht passende Zahl besitzt `;

  const varnichtpassend = ` Die andere nicht passende Zahl besitzt `;

  example.responses = [
    {
      id: "0004",
      category: example.possibleCategories[3], // Array Index not id
      text: "Richtig, hier wird jedes Mal die Zahl (1233)<sub>5</sub> gezeigt.",
    },
    {
      id: "0013",
      text: var3richtig + var322falsch + varachtung,
    },
    {
      id: "0022",
      text:
        var2x2richtig +
        var322falsch +
        varandereZahl +
        var1233falsch +
        varachtung,
    },
    {
      id: "0031",
      text: var3richtig + var1233falsch + varachtung,
    },
    {
      id: "0040",
      category: example.possibleCategories[2], // Array Index not id
      text:
        "Prima. In all diesen Darstellungen wird die Zahl (322)<sub>5</sub> dargestellt.",
    },
    {
      id: "0103",
      text: var3richtig + var320falsch + varachtung,
    },
    {
      id: "0112",
      text:
        var2richtig +
        var320falsch +
        varnichtpassend +
        var322falsch +
        varachtung,
    },
    {
      id: "0121",
      text:
        var2richtig +
        var320falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "0130",
      text: var3richtig + var320falsch + varachtung,
    },
    {
      id: "0202",
      text:
        var2x2richtig +
        var320falsch +
        varandereZahl +
        var1233falsch +
        varachtung,
    },
    {
      id: "0211",
      text:
        var2richtig +
        var322falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "0220",
      text:
        var2x2richtig +
        var320falsch +
        varandereZahl +
        var322falsch +
        varachtung,
    },
    {
      id: "0301",
      text: var3richtig + var1233falsch + varachtung,
    },
    {
      id: "0310",
      text: var3richtig + var322falsch + varachtung,
    },
    {
      id: "0400",
      category: example.possibleCategories[1], // Array Index not id
      text:
        "Sehr gut. Alle vier Darstellungen zeigen die Zahl (320)<sub>5</sub>",
    },
    {
      id: "1003",
      text: var3richtig + var302falsch + varachtung,
    },
    {
      id: "1012",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var322falsch +
        varachtung,
    },
    {
      id: "1021",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "1030",
      text: var3richtig + var302falsch + varachtung,
    },
    {
      id: "1102",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var320falsch +
        varachtung,
    },
    {
      id: "1111",
      text:
        "Upps. Das ist noch sehr durcheinander. Achten Sie auf die Anzahl der 125er-Bündel, 25er-Bündel, 5er-Bündel und Einer der dargestellten Zahlen. Beachten Sie, dass Bündelungszwang herrscht. Wenn von einem Element (beispielsweise 5er oder Einer) 5 oder mehr vorhanden sind, müssen diese zur nächsthöheren Einheit gebündelt werden. Versuchen Sie es erneut!",
    },
    {
      id: "1120",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var320falsch +
        varachtung,
    },
    {
      id: "1201",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "1210",
      text:
        var2richtig +
        var302falsch +
        varnichtpassend +
        var322falsch +
        varachtung,
    },
    {
      id: "1300",
      text: var3richtig + var302falsch + varachtung,
    },
    {
      id: "2002",
      text:
        var2x2richtig +
        var302falsch +
        varandereZahl +
        var1233falsch +
        varachtung,
    },
    {
      id: "2011",
      text:
        var2richtig +
        var322falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "2020",
      text:
        var2x2richtig +
        var302falsch +
        varandereZahl +
        var322falsch +
        varachtung,
    },
    {
      id: "2101",
      text:
        var2richtig +
        var320falsch +
        varnichtpassend +
        var1233falsch +
        varachtung,
    },
    {
      id: "2110",
      text:
        var2richtig +
        var320falsch +
        varnichtpassend +
        var322falsch +
        varachtung,
    },
    {
      id: "2200",
      text:
        var2x2richtig +
        var302falsch +
        varandereZahl +
        var320falsch +
        varachtung,
    },
    {
      id: "3001",
      text: var3richtig + var1233falsch + varachtung,
    },
    {
      id: "3010",
      text: var3richtig + var322falsch + varachtung,
    },
    {
      id: "3100",
      text: var3richtig + var320falsch + varachtung,
    },
    {
      id: "4000",
      category: example.possibleCategories[0], // Array Index not id
      text:
        "Super. Alle vier Darstellungen repräsentieren die Zahl (302)<sub>5</sub>",
    },
  ];

  return example;
}

export default createObject();
