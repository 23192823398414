function createObject() {
  let example = {
    id: "SSZH_2_1",
    left: "klein",
    right: "groß",
    // title: "Stellenwertverständnis: SSZH2_1",
    showGroupNames: false,
    maximalAmountElementsInGroup: 4,
    numberOfPlaceholder: 4,
    checkAfterHowManyItems: 4,
    checkAfterExactItemCount: true,
    workOrder: `
        Hier sehen Sie vier Zahlen in unterschiedlichen Stellenwertsystemen, die in zufälliger Reihenfolge stehen. Ziehen Sie die Zahlen auf die Linie und ordnen Sie diese nach ihrer Größe. Kleine Zahlen stehen dabei links, große Zahlen rechts. Sobald Sie alle vier Zahlen angeordnet haben, drücken Sie auf „Überprüfen“, um eine Rückmeldung zu erhalten.
            `,
    possibleCategories: [{
        id: 1,
        name: "1",
      },
      {
        id: 2,
        name: "2",
      },
      {
        id: 3,
        name: "3",
      },
      {
        id: 4,
        name: "4",
      },
    ],
    elements: [{
        id: 3,
        image: require("@/assets/swve/SSZH_2_1/sszh_2_1_3.png"),
        rank: 4,
        flex: 3,
      },
      {
        id: 1,
        image: require("@/assets/swve/SSZH_2_1/sszh_2_1_1.png"),
        rank: 1,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/swve/SSZH_2_1/sszh_2_1_2.png"),
        rank: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/swve/SSZH_2_1/sszh_2_1_4.png"),
        rank: 3,
        flex: 3,
      },
    ],
  };

  const varRichtig =
    "Prima, Sie haben die Zahlen ihrer Größe nach korrekt geordnet!";
  const varFehler = "Das ist noch nicht ganz richtig. ";
  const varError = "Diese Anordnung ist noch nicht richtig. ";

  const varDiaStellen =
    "Es könnte sein, dass Sie die Zahlen anhand der Anzahl ihrer Stellen geordnet haben – jedoch lässt sich an der Anzahl der Stellen nicht sofort erkennen, welche Zahl die größere ist.";
  const varDiaReihenfolge =
    "Es könnte sein, dass Sie die Zahlen von groß nach klein geordnet haben.";
  const varDiaBasis =
    "Es könnte sein, dass Sie die Zahlen anhand der Größe ihrer Basen geordnet haben.";
  const varDiaLeerstellen =
    "Es könnte sein, dass Sie die Position der Zahlen in der Stellenwerttabelle nicht berücksichtigt haben.";
  const varDiaDeutung1 =
    "Es könnte sein, dass Sie die Zahlen als 7, 11, 12, 14 gedeutet und Leerstellen in der Stellenwerttafel sowie die Basen nicht beachtet haben. Dabei haben Sie absteigend der Größe nach geordnet.";
  const varDiaDeutung2 =
    "Es könnte sein, dass Sie die Zahlen als 7, 11, 12, 14 gedeutet und Leerstellen in der Stellenwerttafel sowie die Basen nicht beachtet haben.";

  const varTipkonkret1 =
    "Schauen Sie sich die Einordnung der (102)<sub>3</sub> nochmal an.";
  const varTipkonkret2 =
    "Schauen Sie sich die Einordnung der (14)<sub>5</sub> nochmal an.";
  const varTipkonkret3 =
    "Schauen Sie sich die Einordnung der (7)<sub>9</sub> nochmal an.";
  const varTipkonkret4 =
    "Schauen Sie sich die Einordnung der (1010)<sub>2</sub> nochmal an.";
  const varTipVorgehen =
    "Durch Addition der Ziffern, multipliziert mit ihrem jeweiligen Stellenwert, lässt sich der Wert einer Zahl im Zehnersystem ermitteln. Die (120)<sub>3</sub> wird also durch 1·3<sup>2</sup> + 2·3<sup>1</sup> + 0·3<sup>0</sup> ins dekadische System umgerechnet.";
  const varTipBasis =
    "An der Basis allein lässt sich nicht immer die Größe einer Zahl ermitteln. Mit zunehmender Länge des Zahlworts werden ihre Stellenwerte größer.";
  const varTipLeerstellen =
    "Leere Stellen in der Stellenwerttafel werden formal als Nullen notiert und bestimmen die Länge des Zahlzeichens. Die Größen der vorhandenen Stellenwerte geben dann Aufschluss über die Größe der Zahl.";
  const varTipReihenfolge =
    "Beginnen Sie mit der kleinsten Zahl links und ordnen Sie aufsteigend nach rechts.";

  example.responses = [{
      id: "1234",
      text: varRichtig,
    },
    {
      id: "1243",
      text: varFehler + varDiaStellen + varTipkonkret1,
    },
    {
      id: "1324",
      text: varFehler + varTipkonkret2,
    },
    {
      id: "1342",
      text: varFehler + varDiaDeutung1 + varTipVorgehen,
    },
    {
      id: "1423",
      text: varFehler + varTipkonkret1,
    },
    {
      id: "1432",
      text: varError + varTipVorgehen,
    },
    {
      id: "2134",
      text: varFehler + varTipkonkret3,
    },
    {
      id: "2143",
      text: varError + varTipVorgehen,
    },
    {
      id: "2314",
      text: varFehler + varTipkonkret3,
    },
    {
      id: "2341",
      text: varError + varTipVorgehen,
    },
    {
      id: "2413",
      text: varError + varTipVorgehen,
    },
    {
      id: "2431",
      text: varFehler + varDiaDeutung2 + varTipVorgehen,
    },
    {
      id: "3124",
      text: varFehler + varTipkonkret4,
    },
    {
      id: "3142",
      text: varError + varTipVorgehen,
    },
    {
      id: "3214",
      text: varError + varTipVorgehen,
    },
    {
      id: "3241",
      text: varError + varTipVorgehen,
    },
    {
      id: "3412",
      text: varFehler + varDiaLeerstellen + varTipLeerstellen,
    },
    {
      id: "3421",
      text: varFehler + varDiaBasis + varTipBasis,
    },
    {
      id: "4123",
      text: varFehler + varTipkonkret1,
    },
    {
      id: "4132",
      text: varError + varTipVorgehen,
    },
    {
      id: "4213",
      text: varError + varTipVorgehen,
    },
    {
      id: "4231",
      text: varError + varTipVorgehen,
    },
    {
      id: "4312",
      text: varError + varTipVorgehen,
    },
    {
      id: "4321",
      text: varFehler + varDiaReihenfolge + varTipReihenfolge,
    },
  ];

  return example;
}

export default createObject();