function createObject() {
    let toolTipStellenwertprinzip = `
    <p>
        <strong>Stellenwertprinzip:</strong> Der Wert einer <strong>Ziffer</strong> ist abhängig von ihrer <strong>Position</strong> im Stellenwertsystem.
    </p>`;
    let toolTipMultiplikativesPrinzip = `
    <p>
        <strong>Multiplikatives Prinzip:</strong> Jede Ziffer einer Zahl gibt die <strong>Anzahl der Bündelungseinheiten</strong> eines Stellenwertes an. Ihr Zahlenwert ergibt sich aus der Multiplikation von Ziffer und Stellenwert.
    </p>`;

    let toolTipBuendelungsprinzip = `
    <p>
        <strong>Bündelungsprinzip:</strong> In einem Stellenwertsystem zur <strong>Basis b</strong> werden immer je <strong> b Einheiten </strong> eines Stellenwertes zu einer Einheit des nächsthöheren Stellenwertes <strong>gebündelt</strong>.
    </p>`;

    let toolTipZiffernvorrat = `
    <p>
        Der <strong>Ziffernvorrat</strong> eines Stellenwertsystems zur <strong>Basis b</strong> beläuft sich auf die natürlichen <strong>Zahlen von 0 bis b - 1</strong>. Zahlwörter wie(5)<sub>3</sub> oder(13)<sub>3</sub> sind ungültig, da <strong>Bündelungszwang</strong> besteht; korrekt wären(12)<sub>3</sub> bzw.(20)<sub>3</sub>.
    </p>`;


    let toolTipStellenPlusMulti = toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip;
    let example = {
        id: '3_SEBH_1_3er_System',
        workOrder: "Die dargestellte Zahl soll stets um vier Einer erhöht werden, indem Plättchen in die Stellenwerttafel gelegt werden.",
        originalSlideWidth: 820,
        originalSlideHeight: 324,
        slides: [{
                image: require('@/assets/swve/3_SEBH_1_3er_System/png/step0.png')
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step1_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step1_animation.mp4'),
                intervention: true,
                interventionText: 'Wie sähe eine Erhöhung um vier Einer in der Stellenwerttabelle aus? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurden ein Plättchen in die <u>3<sup>1</sup>-Spalte</u> und drei Plättchen in die <u>3<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>ein „Dreier“ sowie drei Einer</u> hinzugefügt. Dies entspräche zwar einer Erhöhung um vier Einer. Es wurde jedoch nicht korrekt <u>gebündelt</u>.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ... (Did you mean 'dumb'?)
                        // hintText: 'Prima! Hier wurde jeweils ein Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und eine Erhöhung um (11)<sub>3</sub> durchgeführt.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurden jeweils zwei Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>zwei „Dreier“ sowie zwei Einer</u> hinzugefügt und eine Erhöhung um (22)<sub>3</sub> durchgeführt.',
                        toolTip: toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step2_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step2_animation.mp4'),
                successText: 'Prima! Hier wurde jeweils ein Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und eine Erhöhung um (11)<sub>3</sub> durchgeführt. Dies entspricht einer Erhöhung um vier Einer.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenPlusMulti,
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step3_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step3_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um vier Einer erhöht wird? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurden die Plättchen in den <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalten</u> eine Stelle nach links verschoben. Aus den <u>zwei „Dreiern“</u> wurden damit <u>zwei „Neuner“</u>; aus den <u>zwei Einern</u> wurden <u>zwei „Dreier“</u>. Die Zahl (22)<sub>3</sub> wurde also zu (220)<sub>3</sub> verdreifacht.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurde jeweils ein Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt und damit <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt. Dies entspräche zwar einer Erhöhung um vier Einer. Es wurde jedoch nicht korrekt <u>gebündelt</u>. Da nun die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        // hintText: 'Prima! Hier wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in den <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalten</u> zum jeweils nächsten Stellenwert <u>gebündelt</u>. Die Zahl (22)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (110)<sub>3</sub> erhöht.',
                        toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step4_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step4_animation.mp4'),
                successText: 'Prima! Hier wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in den <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalten</u> zum jeweils nächsten Stellenwert <u>gebündelt</u>. Die Zahl (22)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (110)<sub>3</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step5_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step5_animation.mp4'),
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step6_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step6_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um vier Einer erhöht wird? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        //hintText: 'Prima! Hier wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in der 3<sup>0</sup>-Spalte zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (202)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (220)<sub>3</sub> erhöht.',
                        toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurden <u>ein „Neuner“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in den <u>3<sup>2</sup>- und 3<sup>0</sup>-Spalten</u> zum jeweils nächsten Stellenwert <u>gebündelt</u>. Die Zahl (202)<sub>3</sub> wurde dabei um (101)<sub>3</sub> zu (1010)<sub>3</sub> erhöht. Dies entspricht einer Erhöhung um (10)<sub>10</sub> statt um vier Einer.',
                        toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurde jeweils ein Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt und damit <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt. Dies entspräche zwar einer Erhöhung um vier Einer. Es wurde jedoch nicht korrekt <u>gebündelt</u>. Da nun die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step7_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step7_animation.mp4'),
                successText: 'Prima! Hier wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in der 3<sup>0</sup>-Spalte zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (202)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (220)<sub>3</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step8_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step8_animation.mp4'),
            }, {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step9_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step9_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um vier Einer erhöht wird? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        //hintText: 'Prima! Hier wurde korrekt fortlaufend <u>gebündelt</u>: Es wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in der <u>3<sup>0</sup>-Spalte</u> zu <u>einem „Dreier“</u> gebündelt. Die so entstandenen <u>drei „Dreier“</u> wurden wiederum zu <u>einem „Neuner“</u> in den nächsten Stellenwert gebündelt. Die Zahl (1012)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (1100)<sub>3</sub> erhöht.',
                        toolTip: toolTipBuendelungsprinzip + toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurden die Plättchen in den <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalten</u> eine Stelle nach links verschoben. Aus dem <u>einem „Dreier“</u> wurde damit <u>ein „Neuner“</u>; aus den <u>zwei Einern</u> wurden <u>zwei „Dreier“</u>. Die Zahl (1012)<sub>3</sub> wurde also durch eine Verdreifachung der letzten beiden Stellen zu (1120)<sub>3</sub> erhöht.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurde jeweils ein Plättchen in die <u>3<sup>1</sup>- und 3<sup>0</sup>-Spalte</u> gelegt und damit <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt. Dies entspräche zwar einer Erhöhung um vier Einer. Es wurde jedoch nicht korrekt <u>gebündelt</u>. Da nun die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/3_SEBH_1_3er_System/webm/step10_animation.webm'),
                animationAlt: require('@/assets/swve/3_SEBH_1_3er_System/mp4/step10_animation.mp4'),
                successText: 'Prima! Hier wurde korrekt fortlaufend <u>gebündelt</u>: Es wurden <u>ein „Dreier“ sowie ein Einer</u> hinzugefügt und die drei Plättchen in der <u>3<sup>0</sup>-Spalte</u> zu <u>einem „Dreier“</u> gebündelt. Die so entstandenen <u>drei „Dreier“</u> wurden wiederum zu <u>einem „Neuner“</u> in den nächsten Stellenwert gebündelt. Die Zahl (1012)<sub>3</sub> wurde also korrekt um (11)<sub>3</sub> zu (1100)<sub>3</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipBuendelungsprinzip + toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip,
            },
        ]
    }
    return example;
}

export default createObject();