function createObject() {
    let toolTipStellenwertprinzip = `
    <p>
        <strong>Stellenwertprinzip:</strong> Der Wert einer <strong>Ziffer</strong> ist abhängig von ihrer <strong>Position</strong> im Stellenwertsystem.
    </p>`;
    let toolTipMultiplikativesPrinzip = `
    <p>
        <strong>Multiplikatives Prinzip:</strong> Jede Ziffer einer Zahl gibt die <strong>Anzahl der Bündelungseinheiten</strong> eines Stellenwertes an. Ihr Zahlenwert ergibt sich aus der Multiplikation von Ziffer und Stellenwert.
    </p>`;

    let toolTipBuendelungsprinzip = `
    <p>
        <strong>Bündelungsprinzip:</strong> In einem Stellenwertsystem zur <strong>Basis b</strong> werden immer je <strong> b Einheiten </strong> eines Stellenwertes zu einer Einheit des nächsthöheren Stellenwertes <strong>gebündelt</strong>.
    </p>`;

    let toolTipZiffernvorrat = `
    <p>
        Der <strong>Ziffernvorrat</strong> eines Stellenwertsystems zur <strong>Basis b</strong> beläuft sich auf die natürlichen <strong>Zahlen von 0 bis b - 1</strong>. Zahlwörter wie(7)<sub>5</sub> oder(15)<sub>5</sub> sind ungültig, da <strong>Bündelungszwang</strong> besteht; korrekt wären(12)<sub>5</sub> bzw.(20)<sub>5</sub>.
    </p>`;


    let toolTipStellenPlusMulti = toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip;
    let example = {
        id: '2_SEBH_1_5er_System',
        workOrder: "Die dargestellte Zahl soll stets um zwei Einer erhöht werden, indem Plättchen in die Stellenwerttabelle gelegt werden.",
        originalSlideWidth: 820,
        originalSlideHeight: 324,
        slides: [{
                image: require('@/assets/swve/2_SEBH_1_5er_System/png/step0.png')
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step1_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step1_animation.mp4'),
                intervention: true,
                interventionText: 'Wie sähe eine Erhöhung um zwei Einer in der Stellenwerttabelle aus? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurden zwei Plättchen in die <u>5<sup>1</sup>-Spalte</u> gelegt. Es wurden also <u>zwei „Fünfer“</u> hinzugefügt und eine Erhöhung um (20)<sub>5</sub> durchgeführt. Dies entspricht einer Erhöhung um (10)<sub>10</sub> statt um zwei Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        // hintText: 'Prima! Hier wurden zwei Plättchen in die <u>5<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>zwei Einer</u> hinzugefügt und eine Erhöhung um (2)<sub>5</sub> durchgeführt.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurde jeweils ein Plättchen in die <u>5<sup>1</sup>- und 5<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>ein „Fünfer“ sowie ein Einer</u> hinzugefügt und eine Erhöhung um (11)<sub>5</sub> durchgeführt. Dies entspricht einer Erhöhung um (6)<sub>10</sub> statt um zwei Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step2_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step2_animation.mp4'),
                successText: 'Prima! Hier wurden zwei Plättchen in die <u>5<sup>0</sup>-Spalte</u> gelegt. Es wurden also <u>zwei Einer</u> hinzugefügt und eine Erhöhung um (2)<sub>5</sub> durchgeführt.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenPlusMulti,
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step3_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step3_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um zwei Einer erhöht wird? Wählen Sie den korrekten Endzustand aus!',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurden zwei Plättchen in die <u>5<sup>0</sup>-Spalte</u> gelegt und damit <u>zwei Einer</u> hinzugefügt. Es wurde jedoch nicht korrekt <u>gebündelt</u>. Da die Anzahl der Plättchen in einer Spalte größer ist als die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurden zwei Plättchen in die <u>5<sup>1</sup>-Spalte</u> gelegt und damit <u>zwei „Fünfer“</u> hinzugefügt. Es wurde also eine Erhöhung um (20)<sub>5</sub> durchgeführt. Dies entspricht einer Erhöhung um (10)<sub>10</sub> statt um zwei Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        // hintText: 'Prima! Hier wurden <u>zwei Einer</u> hinzugefügt und die sechs Plättchen in der <u>5<sup>0</sup>-Spalte</u> korrekt zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (234)<sub>5</sub> wurde also um (2)<sub>5</sub> zu (241)<sub>5</sub> erhöht.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step4_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step4_animation.mp4'),
                successText: 'Prima! Hier wurden <u>zwei Einer</u> hinzugefügt und die sechs Plättchen in der <u>5<sup>0</sup>-Spalte</u> korrekt zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (234)<sub>5</sub> wurde also um (2)<sub>5</sub> zu (241)<sub>5</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip,
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step5_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step5_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um zwei Einer erhöht wird? Wählen Sie den korrekten Endzustand aus.',
                interventionOptions: [{
                        title: 'A',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        //hintText: 'Prima! Hier wurde korrekt fortlaufend <u>gebündelt</u>: Es wurden <u>zwei Einer</u> hinzugefügt und die fünf Plättchen in der <u>5<sup>0</sup>-Spalte</u> zu <u>einem „Fünfer“</u> gebündelt. Die so entstandenen <u>fünf „Fünfer“</u> wurden wiederum zu <u>einem „25er“</u> in den nächsten Stellenwert gebündelt. Die Zahl(243)<sub>5</sub> wurde also um (2)<sub>5</sub> zu (300)<sub>5</sub> erhöht.',
                        toolTip: toolTipBuendelungsprinzip + toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurden <u>zwei Einer</u> hinzugefügt und die fünf Plättchen in der <u>5<sup>0</sup>-Spalte</u> zum nächsten Stellenwert <u>gebündelt</u>. Die so entstandenen <u>fünf „Fünfer“</u> wurden jedoch nicht weiter gebündelt. Da nun die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurden zwei Plättchen in die <u>5<sup>0</sup>-Spalte</u> gelegt und somit <u>zwei Einer</u> hinzugefügt. Es wurde jedoch nicht korrekt gebündelt. Da die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipZiffernvorrat,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/2_SEBH_1_5er_System/webm/step6_animation.webm'),
                animationAlt: require('@/assets/swve/2_SEBH_1_5er_System/mp4/step6_animation.mp4'),
                successText: 'Prima! Hier wurde korrekt fortlaufend <u>gebündelt</u>: Es wurden <u>zwei Einer</u> hinzugefügt und die fünf Plättchen in der <u>5<sup>0</sup>-Spalte</u> zu <u>einem „Fünfer“</u> gebündelt. Die so entstandenen <u>fünf „Fünfer“</u> wurden wiederum zu <u>einem „25er“</u> in den nächsten Stellenwert gebündelt. Die Zahl(243)<sub>5</sub> wurde also um (2)<sub>5</sub> zu (300)<sub>5</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipZiffernvorrat,
            },
        ]
    }
    return example;
}

export default createObject();