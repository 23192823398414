<style scoped>
.small-text {
  font-size: 0.6rem;
  line-height: 1rem;
}

.height {
  height: 100%;
}

.fewerPadding {
  padding: 10px;
}

.disableSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by any browser but < IE9 */
}

.ghostClass {
  max-width: max-content; /* very important, otherwise the element if dropped in a group will not be displayed well */
}

.maxImageHeight {
  max-height: 130px;
  min-height: 130px;
  min-width: 130px;
}

#app .placeholderBoxArea {
  /* min-height: 100px; */
  border: 2px dashed rgb(192, 192, 192);
  border-radius: 4px;
  background-color: #f5f5f5;
  font-style: italic;
  transition: background-color 0.5s ease;
  margin-left: 2px;
  height: 100%;
}

#app .placeholderBoxArea .v-card__text {
  color: grey;
}

.internalElementStateRanked {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/background.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.errorContainer {
  height: 200px;
  width: 100%;
  /* margin-left: 170px; */
}

.errorElement {
  position: absolute;
  /* margin-top: -12px; */
  z-index: 2;
  /* transform-origin: 0 0;
  transform: scale(0.6); */
  background: transparent;
  /* height: 200px;
  width: 200px; */
}

/* errors are sorted based on theire occurence left to right */
.errorC {
  /* background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_c.png"); */
  /* background-position: center; Center the image */
  /* background-repeat: no-repeat; Do not repeat the image */
  /* background-size: contain; Resize the background image to cover the entire container */
  z-index: 1;
}

.errorB {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_b.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  margin-left: 33px;
  z-index: 2;
}

.errorA {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_a.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  margin-left: 65px;
  z-index: 4;
}

.errorE {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_e.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  margin-left: 215px;
  z-index: 3;
}

.errorD {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_d.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  margin-left: 245px;
  z-index: 4;
}

.errorF {
  background-image: url("~@/assets/explorativeElemente/LernbausteinSortieren/error_f.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
  margin-left: 420px;
  z-index: 4;
}

.correct {
  background-color: #4caf50 !important;
  opacity: 0.8;
  color: white;
}

.errorCol {
  /* height: 280px; */
  height: 0px;
  position: relative;
}

.workOrder {
  font-size: 16px;
  background-color: #f5f5f5;
}
.col-2 {
  max-width: 20%;
}
</style>
<template>
  <div class="lbst-border">
    <v-row no-gutters style="flex-wrap: nowrap" class="justify-space-between">
      <!-- workOrder -->
      <v-card outlined class="flex-grow-1">
        <v-card-text class="workOrder" v-html="workOrder"></v-card-text>
      </v-card>

      <!-- Info-Dialog -->
      <v-card outlined>
        <v-row no-gutters align="center" style="height: 100%">
          <AppDialogInfo title="Video-Anleitung für das interaktive Element" :elementId="this.id">
            <video
              preload="auto"
              controls
              width="60%"
              style="margin-left: 20%"
              @play="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @fullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @webkitfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @mozfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @msfullscreenchange="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @ended="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @pause="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @seeking="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @enterpictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
              @leavepictureinpicture="
                videoPlayerEvent($event, 'Hilfevideo_LBST_Sortieren.mp4')
              "
            >
              <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
              <source
                src="@/assets/hilfevideos/Hilfevideo_LBST_Sortieren.mp4"
                type="video/mp4"
              />
            </video>
          </AppDialogInfo>
        </v-row>
      </v-card>
    </v-row>

    <!-- Area for the elements -->
    <v-row no-gutters align="center">
      <!-- <v-col cols="12"> -->
      <draggable
        v-model="internalElementState"
        group="things"
        @change="elementsChanged($event, 'Übersicht')"
        class="row"
        draggable=".moveable"
        :style="'min-height: ' + this.elementsAreaHeight + 'px'"
        :forceFallback="isSafari()"
      >
        <template v-if="internalElementState.length > 0">
          <v-col
            v-for="card in internalElementState"
            :key="card.id"
            :cols="card.flex"
            style="cursor: move"
            class="moveable"
          >
            <template v-if="card.text">
              <!-- if our elements are only text -->
              <v-card outlined :elevation="2" :key="card.id" class="height">
                <v-card-text
                  v-if="card.text"
                  class="small-text fewerPadding"
                  v-text="card.text"
                ></v-card-text>
                <resize-observer @notify="handleResizeElements" />
              </v-card>
            </template>
            <template v-else>
              <v-card class="height" style="padding: 6px">
                <AppExpandableImage
                  :src="card.image"
                  contain
                  :parentId="id"
                  :elementId="card.id"
                />
                <resize-observer @notify="handleResizeElements" />
              </v-card>
            </template>
          </v-col>
        </template>
        <v-col cols="3" v-if="internalElementState.length < 1">
          <v-card flat class="placeholderBoxArea">
            <v-card-text>Element hier platzieren</v-card-text>
          </v-card>
        </v-col>
      </draggable>
      <!-- </v-col> -->
    </v-row>

    <!-- Area for responses -->
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-card outlined>
          <v-row
            no-gutters
            align="center"
            :style="'min-height: ' + this.feedbackAreaMinHeight + 'px'"
          >
            <v-col>
              <v-card-text>
                <!-- hint -->
                <AppAlertTooltip
                  v-show="!isCorrect && response"
                  :textHtml="response"
                  type="info"
                  :parentId="id"
                />

                <!-- success -->
                <AppAlertTooltip
                  v-show="isCorrect && response"
                  :textHtml="response"
                  type="success"
                  :parentId="id"
                />
              </v-card-text>
            </v-col>
          </v-row>
          <resize-observer @notify="handleResize" />
        </v-card>
      </v-col>
    </v-row>

    <!-- Area for the ranked elements -->
    <v-row no-gutters>
      <v-col cols="12" class="internalElementStateRanked">
        <div
          :style="
            'font-weight: bold; float: left; margin-right: 20px; padding-top: ' +
            this.elementsAreaHeight / 10 +
            'px'
          "
          v-html="left"
        >
        </div>
        <div
          :style="
            'font-weight: bold; float: right; padding-top: ' +
            this.elementsAreaHeight / 10 +
            'px'
          "
          v-html="right"
        >
        </div>
        <draggable
          v-model="internalElementStateRanked"
          group="things"
          @change="elementsChanged($event, 'Skala')"
          class="row"
          draggable=".moveable"
          style="position: relative"
          :style="
            'min-height: ' +
            (this.elementsAreaHeight - this.rankedElementsHeightReducer) +
            'px'
          "
          :forceFallback="isSafari()"
        >
          <!-- class="row justify-center" will place the element in the center, but we can just drop on the right hand side, on the left hand side, we have to be pixel perfect with the cursor -->
          <!-- <div class="moveable" v-if="internalElementStateRanked.length > 0">
    
          </div> -->
          <template v-if="internalElementStateRanked.length > 0">
            <!-- <v-card
              flat
              class="placeholderBoxArea moveable"
              style="width: 60%; position: absolute; left: 0;"
              :key="-1"
            >
              <v-card-text>Elemente hier platzieren</v-card-text>
            </v-card> -->
            <v-col
              v-for="card in internalElementStateRanked"
              :key="card.id"
              :cols="card.flex"
              style="cursor: move"
              :ref="'rankedElementsRef'"
              class="moveable"
            >
              <template v-if="card.text">
                <!-- if our elements are only text -->
                <v-card outlined :elevation="2" :key="card.id" class="height">
                  <v-card-text
                    v-if="card.text"
                    class="small-text fewerPadding"
                    v-text="card.text"
                  ></v-card-text>
                </v-card>
              </template>
              <template v-if="card.image">
                <v-card class="height" style="padding: 6px">
                  <AppExpandableImage
                    :src="card.image"
                    contain
                    :parentId="id"
                    :elementId="card.id"
                  />
                </v-card>
              </template>
            </v-col>
          </template>
          <v-col cols="4" v-if="internalElementStateRanked.length < 1">
            <v-card flat class="placeholderBoxArea">
              <v-card-text>Element hier platzieren</v-card-text>
            </v-card>
          </v-col>
        </draggable>
      </v-col>
      <!-- ! The specific responses with a,b,c, ... are not needed right now, but we want to use them later -->
      <!-- <v-col
        cols="12"
        :style="'height: ' + this.errorAreaMaxHeight + 'px'"
        :class="[
          {
            errorCol:
              errorOnceShown ||
              errorA ||
              errorB ||
              errorC ||
              errorD ||
              errorE ||
              errorF,
          },
        ]"
      >
        <v-card
          v-if="errorA"
          flat
          @click="showResponse('A', errorA)"
          style="width: 12%; position: absolute; top: 0; left: 25%; background: transparent; z-index: 3"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_a.png"
            ><resize-observer @notify="handleResizeError"
          /></v-img>
        </v-card>
        <v-card
          v-if="errorB"
          flat
          @click="showResponse('B', errorB)"
          style="width: 40%; position: absolute; top: 0; left: 21%; background: transparent; z-index: 1;"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_b.png"
          >
            <resize-observer @notify="handleResizeError"
          /></v-img>
        </v-card>
        <v-card
          v-if="errorC"
          flat
          @click="showResponse('C', errorC)"
          style="width: 68%; position: absolute; top: 0; left: 17%; background: transparent; z-index: 0"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_c.png"
            ><resize-observer @notify="handleResizeError" />
          </v-img>
        </v-card>
        <v-card
          v-if="errorD"
          flat
          @click="showResponse('D', errorD)"
          style="width: 12%; position: absolute; top: 0; left: 47%; background: transparent; z-index: 3"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_d.png"
            ><resize-observer @notify="handleResizeError"
          /></v-img>
        </v-card>
        <v-card
          v-if="errorE"
          flat
          @click="showResponse('E', errorE)"
          style="width: 38%; position: absolute; top: 0; left: 43.5%; background: transparent; z-index: 2"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_e.png"
            ><resize-observer @notify="handleResizeError"
          /></v-img>
        </v-card>
        <v-card
          v-if="errorF"
          flat
          @click="showResponse('F', errorF)"
          style="width: 12%; position: absolute; top: 0; left: 66%; background: transparent; z-index: 3"
        >
          <v-img
            contain
            src="~@/assets/explorativeElemente/LernbausteinSortieren/error_f.png"
            ><resize-observer @notify="handleResizeError"
          /></v-img>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row no-gutters justify="space-between" style="padding-top: 4px">
      <AppButtonReset @click="reset()"></AppButtonReset>
      <AppButtonCheck
        :class="{ correct: isCorrect }"
        color="primary"
        @click="check()"
      ></AppButtonCheck>
    </v-row>
    <AppAlternativeContent
      :id="id"
      :exerciseId="id"
      :trials="trials"
      :solved="solved"
      :reset="resetLBST"
      v-on:reset="resetLBST = false"
    >
      <template v-slot:alternative-content>
        <slot name="alternative-content"></slot>
      </template>
    </AppAlternativeContent>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AppAlertTooltip from "@/common/AppAlertTooltip";
import AppButtonReset from "@/common/AppButtonReset";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppButtonCheck from "@/common/AppButtonCheck";
import AppHelper from "@/common/AppHelper";
import AppAlternativeContent from "@/common/AppAlternativeContent";
import AppDialogInfo from "@/common/AppDialogInfo";

export default {
  components: {
    draggable,
    // LernbausteinSortierenSkala,
    AppAlertTooltip,
    AppButtonReset,
    AppExpandableImage,
    AppButtonCheck,
    AppAlternativeContent,
    AppDialogInfo,
  },
  mounted: function () {
    this.restoreState();
  },
  props: {
    /* The work order that should be displayed */
    workOrder: { String, required: true },

    /* Elements we receive that we should display */
    elements: { Array, required: true },

    /* Array of all posible responses which could be chosed from */
    responses: { Array, required: true },

    /* The work order that should be displayed */
    left: { String, required: true },

    /* The work order that should be displayed */
    right: { String, required: true },

    /* unique id for the store */
    id: { String, required: true },
  },
  data() {
    return {
      /* Variable that holds the current active response that was set by a group */
      response: "",

      /* internal Element State which we use, therefore we are independent from the parent data and can use this.elements to reset the state */
      internalElementState: this.elements,

      /* this array holds the dropped and therefor ranked element */
      internalElementStateRanked: [],

      // size of the feedback area
      feedbackAreaMinHeight: 90,

      // size of the error area
      errorAreaMaxHeight: 0,

      elementsAreaHeight: 0,

      rankedElementsHeightReducer: 18,

      isCorrect: false,

      errorOnceShown: false,

      errorA: false,

      errorB: false,

      errorC: false,

      errorD: false,

      errorE: false,

      errorF: false,

      // number of wrong answers/trials
      trials: 0,

      //if the LBST is solved show additional content
      solved: false,

      resetLBST: false,
    };
  },
  watch: {},
  methods: {
    elementsChanged: function (changeEvent, groupName) {
      // window.console.log(evt);
      this.removeAllErrors();

      if (changeEvent.added) {
        let position = 1; // not 0 based
        if (this.internalElementStateRanked.length == 1) {
          // it's the first drop and we have the placeholder element in our list, so the index is 1 and not 0
          // but we want that it is 0 because we don't want to count the placeholder element
          position = 1; // not 0 based
        } else {
          position = 1 + changeEvent.added.newIndex; // not 0 based
        }

        // we only want added events because we don't want that two events are triggered (add and remove if we move an element from one group to another)
        this.saveState();

        AppHelper.trackMatomoEvent(
          this,
          "LBSTSortieren",
          "id:22; Element: " +
            changeEvent.added.element.id +
            " hinzugefügt zur: " +
            groupName +
            " an Position: " +
            position +
            ";",
          this.id
        );
      } else if (changeEvent.removed) {
        AppHelper.trackMatomoEvent(
          this,
          "LBSTSortieren",
          "id:23; Element: " +
            changeEvent.removed.element.id +
            " entfernt aus: " +
            groupName +
            " von Position: " +
            (1 + changeEvent.removed.oldIndex) +
            ";", // not 0 based
          this.id
        );
      } else if (changeEvent.moved) {
        // we have to save the state if we move an element inside a group
        this.saveState();

        AppHelper.trackMatomoEvent(
          this,
          "LBSTSortieren",
          "id:24; Element: " +
            changeEvent.moved.element.id +
            " verschoben von Position: " +
            (changeEvent.moved.oldIndex + 1) +
            " auf Position: " +
            (changeEvent.moved.newIndex + 1) +
            " innerhalb von: " +
            groupName +
            ";",
          this.id
        );
      }
    },
    reset: function () {
      // Reset the elements
      this.internalElementState = this.elements;
      this.internalElementStateRanked = [];
      this.removeAllErrors();
      this.feedbackAreaMinHeight = 90;
      this.errorAreaMaxHeight = 0;
      this.errorOnceShown = false;
      this.elementsAreaHeight = 0;
      this.trials = 0;
      this.solved = false;
      this.resetLBST = true;
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSortieren",
        "id:25; Reset;",
        this.id
      );
      this.saveState();
    },
    handleResize({ height }) {
      if (this.feedbackAreaMinHeight < height) {
        // ToDo: somehow the feedback area bounces a litte. could be the used border, don't now yet.
        // let randomValue = 3;
        this.feedbackAreaMinHeight = height; // + randomValue;
      }
    },
    handleResizeError({ height }) {
      if (this.errorAreaMaxHeight < height) {
        // ToDo: somehow the feedback area bounces a litte. could be the used border, don't now yet.
        // let randomValue = 3;
        this.errorAreaMaxHeight = height; // + randomValue;
      }
    },
    handleResizeElements({ height }) {
      if (this.elementsAreaHeight < height) {
        // let randomValue = 25;
        this.elementsAreaHeight = height; // + randomValue;
      }
    },

    getErrorMessage(key) {
      for (let response of this.responses) {
        if (response.id == key) {
          return response.text;
        }
      }
    },

    prepareErrors(rank) {
      switch (rank){
        case "1234":
        case "12345":
          this.response = this.getErrorMessage(rank);
          this.isCorrect = true;
          AppHelper.trackMatomoEvent(
            this,
            "LBSTSortieren",
            "id:27; Reihenfolge korrekt. LBST erfolgreich abgeschlossen.;",
            this.id
          );
          break;
        default:
          this.trials++;
          this.response = this.getErrorMessage(rank);
      }
      /*switch (rank) {
        case "1234":
          this.isCorrect = true;
          this.response = this.getErrorMessage("1234");
          AppHelper.trackMatomoEvent(
            this,
            "LBSTSortieren",
            "id:27; Reihenfolge korrekt. LBST erfolgreich abgeschlossen.;",
            this.id
          );
          break;
        case "1243":
          this.errorF = this.getErrorMessage("43");
          this.response = this.getErrorMessage("1243");
          this.trials++;
          break;
        case "1324":
          this.errorD = this.getErrorMessage("32");
          this.response = this.getErrorMessage("1324");
          this.trials++;
          break;
        case "1342":
          this.errorE = this.getErrorMessage("32");
          this.errorF = this.getErrorMessage("42");
          this.response = this.getErrorMessage("1342");
          this.trials++;
          break;
        case "1423":
          this.errorD = this.getErrorMessage("42");
          this.errorE = this.getErrorMessage("43");
          this.response = this.getErrorMessage("1423");
          this.trials++;
          break;
        case "1432":
          this.errorD = this.getErrorMessage("43");
          this.errorE = this.getErrorMessage("42");
          this.errorF = this.getErrorMessage("32");
          this.response = this.getErrorMessage("1432");
          this.trials++;
          break;
        case "2134":
          this.errorA = this.getErrorMessage("21");
          this.response = this.getErrorMessage("2134");
          this.trials++;
          break;
        case "2143":
          this.errorA = this.getErrorMessage("21");
          this.errorF = this.getErrorMessage("43");
          this.response = this.getErrorMessage("2143");
          this.trials++;
          break;
        case "2314":
          this.errorB = this.getErrorMessage("21");
          this.errorD = this.getErrorMessage("31");
          this.response = this.getErrorMessage("2314");
          this.trials++;
          break;
        case "2341":
          this.errorC = this.getErrorMessage("21");
          this.errorE = this.getErrorMessage("31");
          this.errorF = this.getErrorMessage("41");
          this.response = this.getErrorMessage("2341");
          this.trials++;
          break;
        case "2413":
          this.errorB = this.getErrorMessage("21");
          this.errorD = this.getErrorMessage("41");
          this.errorE = this.getErrorMessage("43");
          this.response = this.getErrorMessage("2413");
          this.trials++;
          break;
        case "2431":
          this.errorC = this.getErrorMessage("21");
          this.errorD = this.getErrorMessage("43");
          this.errorE = this.getErrorMessage("41");
          this.errorF = this.getErrorMessage("31");
          this.response = this.getErrorMessage("2431");
          this.trials++;
          break;
        case "3124":
          this.errorA = this.getErrorMessage("31");
          this.errorB = this.getErrorMessage("32");
          this.response = this.getErrorMessage("3124");
          this.trials++;
          break;
        case "3142":
          this.errorA = this.getErrorMessage("31");
          this.errorC = this.getErrorMessage("32");
          this.errorF = this.getErrorMessage("42");
          this.response = this.getErrorMessage("3142");
          this.trials++;
          break;
        case "3214":
          this.errorA = this.getErrorMessage("32");
          this.errorB = this.getErrorMessage("31");
          this.errorD = this.getErrorMessage("21");
          this.response = this.getErrorMessage("3214");
          this.trials++;
          break;
        case "3241":
          this.errorA = this.getErrorMessage("32");
          this.errorC = this.getErrorMessage("31");
          this.errorE = this.getErrorMessage("21");
          this.errorF = this.getErrorMessage("41");
          this.response = this.getErrorMessage("3241");
          this.trials++;
          break;
        case "3412":
          this.errorB = this.getErrorMessage("31");
          this.errorC = this.getErrorMessage("32");
          this.errorD = this.getErrorMessage("41");
          this.errorE = this.getErrorMessage("42");
          this.response = this.getErrorMessage("3412");
          this.trials++;
          break;
        case "3421":
          this.errorB = this.getErrorMessage("32");
          this.errorC = this.getErrorMessage("31");
          this.errorD = this.getErrorMessage("42");
          this.errorE = this.getErrorMessage("41");
          this.errorF = this.getErrorMessage("21");
          this.response = this.getErrorMessage("3421");
          this.trials++;
          break;
        case "4123":
          this.errorA = this.getErrorMessage("41");
          this.errorB = this.getErrorMessage("42");
          this.errorC = this.getErrorMessage("43");
          this.response = this.getErrorMessage("4123");
          this.trials++;
          break;
        case "4132":
          this.errorA = this.getErrorMessage("41");
          this.errorB = this.getErrorMessage("43");
          this.errorC = this.getErrorMessage("42");
          this.errorF = this.getErrorMessage("32");
          this.response = this.getErrorMessage("4132");
          this.trials++;
          break;
        case "4213":
          this.errorA = this.getErrorMessage("42");
          this.errorB = this.getErrorMessage("41");
          this.errorC = this.getErrorMessage("43");
          this.errorD = this.getErrorMessage("21");
          this.response = this.getErrorMessage("4213");
          this.trials++;
          break;
        case "4231":
          this.errorA = this.getErrorMessage("42");
          this.errorB = this.getErrorMessage("43");
          this.errorC = this.getErrorMessage("41");
          this.errorE = this.getErrorMessage("21");
          this.errorF = this.getErrorMessage("31");
          this.response = this.getErrorMessage("4231");
          this.trials++;
          break;
        case "4312":
          this.errorA = this.getErrorMessage("43");
          this.errorB = this.getErrorMessage("41");
          this.errorC = this.getErrorMessage("42");
          this.errorD = this.getErrorMessage("31");
          this.errorE = this.getErrorMessage("32");
          this.response = this.getErrorMessage("4312");
          this.trials++;
          break;
        case "4321":
          this.errorA = this.getErrorMessage("43");
          this.errorB = this.getErrorMessage("42");
          this.errorC = this.getErrorMessage("41");
          this.errorD = this.getErrorMessage("32");
          this.errorE = this.getErrorMessage("31");
          this.errorF = this.getErrorMessage("21");
          this.response = this.getErrorMessage("4321");
          this.trials++;
          break;
        default:
          return {};
      }*/
    },

    check() {
      AppHelper.trackMatomoEvent(
        this,
        "LBSTSortieren",
        "id:26; Überprüfen betätigt;",
        this.id
      );
      this.errorOnceShown = true;
      this.removeAllErrors();
      let rank = "";
      for (let element of this.internalElementStateRanked) {
        rank += element.rank;
      }
      if (rank.length < this.elements.length) {
        this.response = "Bitte ordne zunächst alle " + this.elements.length +" Elemente an.";
      } else {
        this.prepareErrors(rank);
      }
      if (this.isCorrect) {
        this.solved = true;
      }
      this.saveState();
    },

    removeAllErrors() {
      this.isCorrect = false;
      this.response = "";
      this.errorA = "";
      this.errorB = "";
      this.errorC = "";
      this.errorD = "";
      this.errorE = "";
      this.errorF = "";
    },

    showResponse(errorId, error) {
      this.response = errorId + ": " + error;
    },

    restoreState() {
      const storeId = "LBSTSortieren" + this.id;

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storeId]
        : false;

      if (restoredState && restoredState.data.feedbackAreaMinHeight) {
        // it is possible to receive an empty data object, so we check if there is any data by using the feedbackAreaMinHeight attribute
        this.response = restoredState.data.response;
        this.feedbackAreaMinHeight = restoredState.data.feedbackAreaMinHeight;
        this.errorAreaMaxHeight = restoredState.data.errorAreaMaxHeight;
        // this.elementsAreaHeight = restoredState.data.elementsAreaHeight;
        this.rankedElementsHeightReducer =
          restoredState.data.rankedElementsHeightReducer;
        this.isCorrect = restoredState.data.isCorrect;
        this.errorOnceShown = restoredState.data.errorOnceShown;
        this.errorA = restoredState.data.errorA;
        this.errorB = restoredState.data.errorB;
        this.errorC = restoredState.data.errorC;
        this.errorD = restoredState.data.errorD;
        this.errorE = restoredState.data.errorE;
        this.errorF = restoredState.data.errorF;
        let newInternalElementState = [];
        let elementsInGroups = [];
        for (let element of this.internalElementState) {
          // we create a new array, get the elements from this.internalElementState and position them based on the restoredState
          let added = false;
          for (let [
            index,
            val,
          ] of restoredState.data.internalElementState.entries() || []) {
            if (element.id == val) {
              newInternalElementState[index] = element;
              added = true;
            }
          }
          if (!added) {
            // the element was not added to the internalElementState so they have to be on the skala
            elementsInGroups.push(element);
          }
        }

        let tempElements = [];
        for (let element of elementsInGroups) {
          for (let [
            index,
            val,
          ] of restoredState.data.internalElementStateRanked.entries() || []) {
            if (element.id == val) {
              tempElements[index] = element;
            }
          }
        }

        this.internalElementState = newInternalElementState;
        this.internalElementStateRanked = tempElements;

        //              internalElementState: payload.internalElementState.map(
        //   (internalElement) => internalElement.id
        // ),
        // internalElementStateRanked: payload.internalElementStateRanked.map(
        //   (internalElement) => internalElement.id
        // ),
        this.solved = restoredState.data.solved;
      }
      if (!this.$store.hasModule(["nested", storeId])) {
        this.$store.registerModule(["nested", storeId], {
          namespaced: true,
          state: {
            data: {}, // it's very imporant to create the necessary attributes before
          },
          mutations: {
            data(state, payload) {
              state.data = {
                response: payload.response,
                internalElementState: payload.internalElementState.map(
                  (internalElement) => internalElement.id
                ),
                internalElementStateRanked:
                  payload.internalElementStateRanked.map(
                    (internalElement) => internalElement.id
                  ),
                feedbackAreaMinHeight: payload.feedbackAreaMinHeight,
                errorAreaMaxHeight: payload.errorAreaMaxHeight,
                // elementsAreaHeight: payload.elementsAreaHeight,
                rankedElementsHeightReducer:
                  payload.rankedElementsHeightReducer,
                isCorrect: payload.isCorrect,
                errorOnceShown: payload.errorOnceShown,
                errorA: payload.errorA,
                errorB: payload.errorB,
                errorC: payload.errorC,
                errorD: payload.errorD,
                errorE: payload.errorE,
                errorF: payload.errorF,
                solved: payload.solved,
              };
            },
          },
        });
        // VERY IMPORTANT! If missing this state is not registered in the nested store state and will not be send to the server if another component updates it state
        this.saveStateLocal(); // save the current state, so even if we don't change anything the last state will be send to the server
      }
    },
    saveStateLocal: function () {
      const storeId = "LBSTSortieren" + this.id;
      this.$store.commit("nested/" + storeId + "/data", { ...this._data });
    },
    saveState: function () {
      this.saveStateLocal();
      // send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            // if we have an error, we don't save the state
            // console.log(err);
            // network error can be invalid token, be aware!
          });
      } catch (err) {
        // if we have an error, we don't save the state
        // console.log("down " + err);
      }
    },
    isSafari() {
      /*
        https: //github.com/SortableJS/Sortable/issues/1571#issuecomment-753500530
        Safari has an issue with dnd so we need an detection to decide if we want to use the fallback method of sortablejs
        we just can't always use the fallback method because in firefox if we drag horizontaly on the position of another element our expandImage mechanismn is triggered :(
        */
      return AppHelper.isSafari();
    },

    videoPlayerEvent(event, name) {
      AppHelper.videoPlayerEvent(this, event, name);
    },
  },
};
</script>
