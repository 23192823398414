function createObject() {
    let toolTipStellenwertprinzip = `
    <p>
        <strong>Stellenwertprinzip:</strong> Der Wert einer <strong>Ziffer</strong> ist abhängig von ihrer <strong>Position</strong> im Stellenwertsystem.
    </p>`;
    let toolTipMultiplikativesPrinzip = `
    <p>
        <strong>Multiplikatives Prinzip:</strong> Jede Ziffer einer Zahl gibt die <strong>Anzahl der Bündelungseinheiten</strong> eines Stellenwertes an. Ihr Zahlenwert ergibt sich aus der Multiplikation von Ziffer und Stellenwert.
    </p>`;

    let toolTipBuendelungsprinzip = `
    <p>
        <strong>Bündelungsprinzip:</strong> In einem Stellenwertsystem zur <strong>Basis b</strong> werden immer je <strong> b Einheiten </strong> eines Stellenwertes zu einer Einheit des nächsthöheren Stellenwertes <strong>gebündelt</strong>.
    </p>`;

    let toolTipZiffernvorrat = `
    <p>
        Der <strong>Ziffernvorrat</strong> eines Stellenwertsystems zur <strong>Basis b</strong> beläuft sich auf die natürlichen <strong>Zahlen von 0 bis b - 1</strong>. Zahlwörter wie(6)<sub>4</sub> oder(14)<sub>4</sub> sind ungültig, da <strong>Bündelungszwang</strong> besteht; korrekt wären(12)<sub>4</sub> bzw.(20)<sub>4</sub>.
    </p>`;

    let toolTipStellenPlusMulti = toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip;
    let example = {
        id: '1_SEBH_1_4er_System',
        workOrder: "Die dargestellte Zahl soll stets um einen Einer erhöht werden, indem Plättchen in die Stellenwerttabelle gelegt werden.",
        originalSlideWidth: 820,
        originalSlideHeight: 324,
        slides: [{
                image: require('@/assets/swve/1_SEBH_1_4er_System/png/step0.png')
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step1_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step1_animation.mp4'),
                intervention: true,
                interventionText: 'Wie sähe eine Erhöhung um einen Einer in der Stellenwerttabelle aus? Wählen Sie den korrekten Endzustand aus.',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurde ein Plättchen in die <u>4<sup>1</sup>-Spalte</u> gelegt. Es wurde also <u>ein „Vierer“</u> hinzugefügt und eine Erhöhung um (10)<sub>4</sub> durchgeführt. Dies entspricht einer Erhöhung um (4)<sub>10</sub> statt um einen Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurde ein Plättchen in die <u>4<sup>2</sup>-Spalte</u> gelegt. Es wurde also <u>ein „Sechzehner“</u> hinzugefügt und eine Erhöhung um (100)<sub>4</sub> durchgeführt. Dies entspricht einer Erhöhung um (16)<sub>10</sub> statt um einen Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        //hintText: 'Prima! Hier wurde ein Plättchen in die <u>4<sup>0</sup>-Spalte</u> gelegt. Es wurde also <u>ein Einer</u> hinzugefügt und eine Erhöhung um (1)<sub>4</sub> durchgeführt.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step2_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step2_animation.mp4'),
                successText: 'Prima! Hier wurde ein Plättchen in die <u>4<sup>0</sup>-Spalte</u> gelegt. Es wurde also <u>ein Einer</u> hinzugefügt und eine Erhöhung um (1)<sub>4</sub> durchgeführt.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenPlusMulti,
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step3_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step3_animation.mp4'),
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step4_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step4_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um einen Einer erhöht wird? Wählen Sie den korrekten Endzustand aus.',
                interventionOptions: [{
                        title: 'A',
                        correct: false,
                        hintText: 'Hier wurde ein Plättchen in die <u>4<sup>0</sup>-Spalte</u> gelegt und damit <u>ein Einer</u> hinzugefügt. Es wurde jedoch nicht korrekt <u>gebündelt</u>. Da die Anzahl der Plättchen in einer Spalte genau so groß ist wie die Basis, ist der in der Aufgabe geforderte <u>Bündelungszwang</u> nicht erfüllt.',
                        toolTip: toolTipStellenwertprinzip + toolTipMultiplikativesPrinzip + toolTipBuendelungsprinzip + toolTipZiffernvorrat,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        // hintText: 'Prima! Hier wurde <u>ein Einer</u> hinzugefügt und die vier Plättchen in der <u>4<sup>0</sup>-Spalte</u> korrekt zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (3)<sub>4</sub> wurde also um (1)<sub>4</sub> zu (10)<sub>4</sub> erhöht.',
                        toolTip: toolTipMultiplikativesPrinzip + toolTipStellenwertprinzip + toolTipBuendelungsprinzip,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurde ein Plättchen in die <u>4<sup>1</sup>-Spalte</u> gelegt. Es wurde also <u>ein „Vierer“</u> hinzugefügt und die Zahl (3)<sub>4</sub> um (10)<sub>4</sub> zu (13)<sub>4</sub> erhöht. Dies entspricht einer Erhöhung um (4)<sub>10</sub> statt um einen Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step5_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step5_animation.mp4'),
                successText: 'Prima! Hier wurde <u>ein Einer</u> hinzugefügt und die vier Plättchen in der <u>4<sup>0</sup>-Spalte</u> korrekt zum nächsten Stellenwert <u>gebündelt</u>. Die Zahl (3)<sub>4</sub> wurde also um (1)<sub>4</sub> zu (10)<sub>4</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenPlusMulti,
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step6_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step6_animation.mp4'),
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step7_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step7_animation.mp4'),
                intervention: true,
                interventionText: 'Wie geht es weiter, wenn die Zahl erneut um einen Einer erhöht wird? Wählen Sie den korrekten Endzustand aus.',
                interventionOptions: [{
                        title: 'A',
                        correct: true,
                        // ATTENTION!!! We get the successText from the next slide! I know, dump ...
                        //hintText: 'Prima! Hier wurde ein Plättchen in die <u>4<sup>0</sup>-Spalte</u> gelegt. Es wurde also <u>ein Einer</u> hinzugefügt und die Zahl (12)<sub>4</sub> korrekt um (1)<sub>4</sub> zu (13)<sub>4</sub> erhöht.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 13,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'B',
                        correct: false,
                        hintText: 'Hier wurde ein Plättchen in die <u>4<sup>1</sup>-Spalte</u> gelegt. Es wurde also <u>ein „Vierer“</u> hinzugefügt und die Zahl (12)<sub>4</sub> um (10)<sub>4</sub> zu (22)<sub>4</sub> erhöht. Dies entspricht einer Erhöhung um (4)<sub>10</sub> statt um einen Einer.',
                        toolTip: toolTipStellenPlusMulti,
                        top: 122,
                        left: 441,
                        width: 377,
                        height: 77
                    },
                    {
                        title: 'C',
                        correct: false,
                        hintText: 'Hier wurden die Plättchen in der <u>4<sup>0</sup>-Spalte</u> inkorrekt zum nächsten Stellenwert <u>gebündelt</u>. Nach dem Hinzufügen <u>eines Einers</u> sind darin jedoch nicht genug Plättchen vorhanden, um diese bündeln zu können.',
                        toolTip: toolTipStellenwertprinzip + toolTipBuendelungsprinzip + toolTipMultiplikativesPrinzip,
                        top: 231,
                        left: 441,
                        width: 377,
                        height: 77
                    }
                ]
            },
            {
                animation: require('@/assets/swve/1_SEBH_1_4er_System/webm/step8_animation.webm'),
                animationAlt: require('@/assets/swve/1_SEBH_1_4er_System/mp4/step8_animation.mp4'),
                successText: 'Prima! Hier wurde ein Plättchen in die <u>4<sup>0</sup>-Spalte</u> gelegt. Es wurde also <u>ein Einer</u> hinzugefügt und die Zahl (12)<sub>4</sub> korrekt um (1)<sub>4</sub> zu (13)<sub>4</sub> erhöht.',
                // ATTENTION!!! We get the toolTip from the previous slide! I know, dump ...
                //toolTip: toolTipStellenPlusMulti,
            },
        ]
    }
    return example;
}

export default createObject();